import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { Spinner } from "react-bootstrap";

const CareersForm = (props) => {
  const [formSuccess, setFormSuccess] = useState(false);
  const [formFail, setFormFail] = useState(false);
  const [fileData, setFileData] = useState({
    FileName: "",
    Base64File: ""
  });

  const [isDisabled, setIsDisabled] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        setFileData({
          FileName: file.name,
          Base64File: reader.result
        });
      };
    }
  };

  const onSubmit = async (values, form) => {
    setIsDisabled(true);
    setFormFail(false);
    setFormSuccess(false);
    const formData = {
      Name: values.name,
      Position: values.position,
      ContactNo: values.contact_no,
      Email: values.email,
      FileName: fileData.FileName,
      Base64File: fileData.Base64File.split(",").slice(1).join("")
    };
    
    return fetch(
      "https://779ymlqzh3.execute-api.ap-southeast-2.amazonaws.com/default/ItelasoftCareerLambda",
      {
        mode: "no-cors",
        method: "POST",
        body: JSON.stringify(formData),
        headers: new Headers({
          "Content-Type": "application/json"
        })
      }
    )
      .then((res) => {
        form.pauseValidation();
        form.restart();
        setFormFail(false);
        setFormSuccess(true);
        setIsDisabled(false);
        setTimeout(() => setFormSuccess(false), 10000);
      })
      .catch((err) => {
        // console.log(err);
        setFormSuccess(false);
        setFormFail(true);
        setIsDisabled(false);
        setTimeout(() => setFormFail(false), 10000);
      });
  };

  return (
    <>
    <div className="title title-2 apply-now">Are you the talent we are looking for?</div>
    <div className="title title-3 apply-now mb-4">Apply Now!</div>
    <Form
      onSubmit={onSubmit}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = "Required";
        }
        if (!values.email) {
          errors.email = "Required";
        }
        // else if () {
        //     errors.from = "Valid Email Required";
        // }
        if (!values.position) {
          errors.position = "Required";
        }
        if (!values.contact_no) {
          errors.contact_no = "Required";
        }
        if (!values.resume) {
          errors.resume = "Required";
        }
        return errors;
      }}
      initialValues={{
        to: "careers@itelasoft.com",
        position: props.careerTitle ? props.careerTitle : "",
      }}
      render={({ handleSubmit, reset, form, submitting, pristine, values }) => (
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="wr-form mb-xs-5">
            <div className="form-group">
              {/* <label>First Name</label> */}
              <Field name="name">
                {({ input, meta }) => (
                  <div>
                    <label className="control-label">
                      <div>YOUR NAME (REQUIRED)</div>
                    </label>
                    <input {...input} type="text" className="form-control" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="form-group">
              <div className="form-group">
                <Field name="position">
                  {({ input, meta }) => (
                    <div>
                      <label className="control-label">
                        <div>POSITION APPLYING FOR (REQUIRED)</div>
                      </label>
                      <input {...input} type="text" className="form-control" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form-group">
              <div className="form-group">
                <Field name="contact_no">
                  {({ input, meta }) => (
                    <div>
                      <label className="control-label">
                        <div>CONTACT NO (REQUIRED)</div>
                      </label>
                      <input {...input} type="text" className="form-control" />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form-group">
              <Field name="email">
                {({ input, meta }) => (
                  <div>
                    <label className="control-label">
                      <div>EMAIL (REQUIRED)</div>
                    </label>
                    <input {...input} type="text" className="form-control" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="form-group">
              <Field name="resume">
                {({ input, meta }) => (
                  <div>
                    <label className="control-label">
                      <div>ATTACH RESUME (REQUIRED)</div>
                    </label>
                    <input
                      {...input}
                      value={input.value}
                      onChange={(e) => {
                        console.log(e);
                        input.onChange(e);
                        handleFileChange(e);
                      }}
                      type="file"
                      className="form-control"
                    />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field>
            </div>
            <div className="form-group">
              <Field name="to">
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="hidden" />
                  </div>
                )}
              </Field>
              {/* <Field name="message" className="form-control" component="textarea" placeholder="Message" /> */}
            </div>
            <div className="submit-button buttons text-center text-md-left">
              <button
                className={"btn btn-primary spinner-btn" + (isDisabled ? " btn-disabled" : "")}
                type="submit"
                disabled={isDisabled}
                onClick={() => form.resumeValidation()}
              >
                Send Message
                {isDisabled && (
                  <div className="form-spinner-wrapper">
                    <span class="form-spinner loader"></span>
                  </div>
                )}
              </button>
            </div>
          </div>
          <div
            className={
              formSuccess ? "form-msg success show" : "form-msg success"
            }
          >
            <span>
              Thanks for your message! <br /> <span style={{fontWeight: 400, padding: 0}}>We'll be in touch with you shortly.</span>
            </span>
          </div>
          <div className={formFail ? "form-msg fail show" : "form-msg fail"}>
            <span>
              There was a problem sending your message. Please try again.
            </span>
          </div>
        </form>
      )}
    />
    </>
  );
};

export default CareersForm;
