import React, { useEffect, useState } from "react";
import Seo from "../../components/Seo";
import Layout from "../../layouts";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import CareersForm from "./careersForm";
import map from "../../images/icons/map-pin-white.svg";

const Apply = (props) => {
  const [careerTitle, setCareerTitles] = useState([]);
  const [career, setCareer] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    const title = new URL(window.location.href).searchParams.get("title");
    setCareerTitles(title);
  }, []);

  // const [careers, setCareers] = useState([]);

  useEffect(async () => {
    try {
      const results = await axios.get(
        "https://analytics-internal.itelasoft.com.au/PublicData/JobOpenings",
        {
          headers: {
            "x-api-key": "6fa78d2c16d34c6aa31bd6073ae86b5b",
          },
        }
      );
      // setCareers(results.data);

      //const id = new URL(window.location.href).searchParams.get('id');
      const career = results.data.find((c) => c.id === id);
      console.log(career);
      setCareer(career);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Layout bodyClass="page-apply">
      <Seo title="Apply" description="Join iTelSoft" />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.applyImg.childImageSharp.fluid}
            loading="eager"
            fadeIn
            alt="Banner Image"
          />
        </div>
        <div className="container">
          <div className="row main-page career-page">
            <div className="col-12">
              <div className="career-page-title">
                Apply for Internship in iTelaSoft
              </div>
              <div className="page-subtitle">
                <div className="job-location">
                  <div className="d-flex">
                    <img
                      class="map"
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLW1hcC1waW4iPjxwYXRoIGQ9Ik0yMSAxMGMwIDctOSAxMy05IDEzcy05LTYtOS0xM2E5IDkgMCAwIDEgMTggMHoiPjwvcGF0aD48Y2lyY2xlIGN4PSIxMiIgY3k9IjEwIiByPSIzIj48L2NpcmNsZT48L3N2Zz4="
                    />
                    <span className="location-text intern-location-wrapper">
                      <span class="inter-location-text">Sri Lanka, India</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" id="career-apply">
        <section className="apply-career-section">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 card-bg">
              <div className="card-apply">
                <div className="job-opportunity space-jb mb-4">
                  <div className="job-description">
                    <h5 className="title title-1 mb-2">
                      We're excited to announce that we are currently seeking
                      interns to join our team!{" "}
                    </h5>
                    <div className="description">
                      If you're passionate about gaining hands-on experience,
                      expanding your skill set, and working in a dynamic and
                      innovative environment, this is the perfect opportunity
                      for you. <br />
                      As an intern, you will have the chance to collaborate with
                      talented professionals, contribute to real-world projects,
                      and develop valuable industry knowledge.We welcome
                      enthusiastic individuals who are eager to learn and grow.{" "}
                      <br />
                      Join us as we embark on an exciting journey of discovery
                      and make a significant impact together. Don't miss out on
                      this incredible chance to kickstart your career. Apply now
                      and be a part of our exceptional team!
                    </div>
                  </div>
                </div>
                <div className="job-coreSkills space-jb">
                  <div className="coreSkills">
                    <h5>Specializations are as follows: </h5>
                    <ul>
                      <li>Frontend Internship</li>
                      <li>Backend Internship</li>
                      <li>Fullstack Internship</li>
                      <li>DevOps Internship</li>
                      <li>Cyber Security Internship</li>
                      <li>WSO2 Dev Internship</li>
                      <li>System Engineering Internship</li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <b>
                    Selected candidates will have the opportunity to go through
                    an extensive Training Programme and learn from the best in
                    the industry!
                    </b>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-8 col-sm-12 form-bg">
              <CareersForm career={careerTitle} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    applyImg: file(relativePath: { eq: "Internship.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Apply;
